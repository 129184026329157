<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                elevation="30"
                shaped
                tile
                small
                class="font-size-h6 mr-3 white--text"
                @click.prevent="refreshPageData"
                color="#a4c639"
              >Refresh</v-btn>
              <br />
            </v-col>
          </v-row>
          <v-container>
            <v-form ref="form" v-model="valid1" lazy-validation v-on:submit.prevent="searchForm">
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Year
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="YearCodeOptionsLoading"
                    :items="YearCodeOptions"
                    :rules="YearCodeRules"
                    v-model="YearCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Team
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="TeamCodeOptionsLoading"
                    :items="TeamCodeOptions"
                    :rules="TeamCodeRules"
                    v-model="TeamCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" v-if="TeamCode == 22 || TeamCode == 24">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Zone
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="ZoneCodeOptionsLoading"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="searchForm"
                    :loading="SearchFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Search</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-if="ResultFlag">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-simple-table>
                  <template>
                    <thead>
                      <tr>
                        <th class="text-center">
                          <h2>Member</h2>
                        </th>
                        <th class="text-center">
                          <h2>Designation</h2>
                        </th>
                        <th class="text-center">
                          <h2>Order</h2>
                        </th>
                        <th>
                          <v-btn icon color="blue" @click="addNewRow(2)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, k) in AllMembers" :key="k">
                        <td>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="MemberIdOptionsLoading"
                            :items="MemberIdOptions"
                            :rules="MemberIdRules"
                            v-model="item.MemberId"
                            required
                            outlined
                            dense
                            chips
                            deletable-chips
                            clearable
                          >
                            <template v-slot:selection="{ item }">
                              <img width="50" height="50" :src="item.photo" />
                              {{
                              item.text
                              }}
                            </template>
                            <template v-slot:item="{ item }">
                              <img width="50" height="50" :src="item.photo" />
                              {{
                              item.text
                              }}
                            </template>
                          </v-autocomplete>
                        </td>
                        <td>
                          <v-autocomplete
                            :reduce="(option) => option.value"
                            :loading="DesignationIdOptionsLoading"
                            :items="DesignationIdOptions"
                            :rules="DesignationIdRules"
                            v-model="item.DesignationId"
                            required
                            outlined
                            dense
                            chips
                            deletable-chips
                            clearable
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="item.Order"
                            :rules="MemberNameRules"
                            label="Order"
                            required
                            outlined
                          ></v-text-field>
                        </td>
                        <td>
                          <v-btn icon color="blue" @click="addNewRow(2)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                          <v-btn icon color="red" @click="deleteRow(2, k, item)">
                            <v-icon>mdi-delete-circle-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  type="submit"
                  color="success"
                  class="mr-4"
                  @click.prevent="submitForm"
                >Save</v-btn>
              </v-col>
              <v-btn
                @click="closePrompt"
                elevation="30"
                shaped
                tile
                small
                color="#a52a2a"
                class="font-size-h6 px-5 mr-2 white--text"
              >Close</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      AllMembers: [],

      YearCodeRules: [v => !!v || "Year is required"],
      YearCode: "",
      YearCodeOptions: [],
      YearCodeOptionsLoading: false,

      TeamCodeRules: [v => !!v || "Team is required"],
      TeamCode: "",
      TeamCodeOptions: [],
      TeamCodeOptionsLoading: false,

      ZoneCodeRules: [v => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      MemberIdRules: [v => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      DesignationIdRules: [v => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.getYearCodeOptions();
        this.getTeamCodeOptions();
        this.getZoneCodeOptions();
        this.getDesignationIdOptions();
        this.getMemberIdOptions();
      }
    },
    YearCodeOptions: function() {
      console.log("watch YearCodeOptions");
      this.YearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    TeamCodeOptions: function() {
      console.log("watch TeamCodeOptions");
      this.TeamCodeOptionsLoading = false;
    },
    MemberIdOptions: function() {
      console.log("watch MemberIdOptions");
      this.MemberIdOptionsLoading = false;
    },
    DesignationIdOptions: function() {
      console.log("watch MemberIdOptions");
      this.DesignationIdOptionsLoading = false;
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "wm_team_members",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      this.YearCodeOptionsLoading = true;
      var selectbox1_source = "YearCode";
      var selectbox1_destination = "YearCodeOptions";
      var selectbox1_url = "api/wm/year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTeamCodeOptions() {
      console.log("getTeamCodeOptions called");
      this.TeamCodeOptionsLoading = true;
      var selectbox1_source = "TeamCode";
      var selectbox1_destination = "TeamCodeOptions";
      var selectbox1_url = "api/wm/team/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/wm/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 2
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);

      if (tableId == 2) {
        var n1 = this.AllMembers.length;
        console.log("n1=" + n1);
        // if (n1 < 30) {
        this.AllMembers.push({
          YearTeamId: 0,
          MemberId: 0,
          MemberName: "",
          DesignationId: 0,
          Designation: "",
          Order: ""
        });
        // }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 2) {
          this.AllMembers.splice(index, 1);
        }
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/wm/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/wm/team/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 1
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.AllMembers = [];

        var YearCode = this.YearCode;
        var TeamCode = this.TeamCode;
        var ZoneCode = this.ZoneCode;
        console.log(
          "YearCode=" +
            YearCode +
            ", TeamCode=" +
            TeamCode +
            ", ZoneCode=" +
            ZoneCode
        );

        var UserInterface = 1;

        switch (TeamCode) {
          case 22:
            UserInterface = 2;
            break;
          case 22:
            UserInterface = 5;
            break;

          default:
            break;
        }
        console.log("UserInterface=" + UserInterface);

        if (YearCode != "" && TeamCode != "") {
          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/wm/yearwise/team/members/lists";
          var upload = {
            UserInterface: UserInterface,
            YearCode: YearCode,
            LomCode: ZoneCode,
            TeamCode: TeamCode
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );

          this.ResultFlag = false;
          this.SearchFlag = true;

          const thisIns = this;
          var output = "";
          var records = "";
          var successTxt = "";
          var errorTxt = "";
          var flag = 0;

          // thisIns.pageLoadingOn()
          thisIns.ProgessStart = 1;

          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            },
            onUploadProgress: function(progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this)
          })
            .then(function(response) {
              console.log("response=" + JSON.stringify(response));

              // thisIns.pageLoadingOff()
              thisIns.ProgessStart = 0;
              thisIns.ResultFlag = true;
              thisIns.SearchFlag = false;

              output = response.data.output;
              flag = response.data.flag;
              output = response.data.output;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);

              if (flag == 1) {
                thisIns.AllMembers = records;
              } else {
                thisIns.sweetAlert("error", output, false);
              }
            })
            .catch(function(error) {
              console.log("error=" + error);
              thisIns.SearchFlag = false;
              thisIns.sweetAlert("error", error, false);
            });
        } else {
          var message = "";
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (TeamCode == "") {
            message += "Team should not be empty. ";
          }
          this.sweetAlert("error", message, false);
        }
      }
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      var validate1 = this.$refs.form.validate();

      var YearCode = this.YearCode;
      var TeamCode = this.TeamCode;
      var ZoneCode = this.ZoneCode;
      var AllMembers = this.AllMembers;
      var n1 = AllMembers.length;

      console.log(
        "YearCode=" +
          YearCode +
          ", TeamCode=" +
          TeamCode +
          ", ZoneCode=" +
          ZoneCode +
          ", n1=" +
          n1
      );

      if (validate1 && YearCode != "" && TeamCode != "" && n1 > 0) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/wm/yearwise/team/members/update";
        var upload = {
          YearCode: YearCode,
          TeamCode: TeamCode,
          ZoneCode: ZoneCode,
          AllMembers: AllMembers
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.sweetAlert("error", output, false);
          });
      } else {
        console.log("some errors occured");
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (TeamCode == "") {
          message += "Team should not be empty. ";
        }
        if (n1 == "") {
          message += "Atleast one member is required. ";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>